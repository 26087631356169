<template>
    <v-row justify="center">
        <div v-if="dialog && configApp">
            <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar>
                        <v-btn icon @click="$emit('onCloseDialog')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ !configApp._id ? $t('title.create_app') : $t('title.update_app')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn :disabled="loading"
                                   text
                                   @click="validate">
                                {{ !configApp._id ? $t('button.create') : $t('button.update') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <Loading :visible="loading" />
                    <v-card-text>
                        <v-container>
                            <v-form ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="configApp.name"
                                                      :rules="rules.name"
                                                      :label="$t('input.app_name')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="configApp.api_player_link"
                                                      :rules="rules.api_player_link"
                                                      :label="$t('input.api_player_link')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="configApp.api_admin_link"
                                                      :rules="rules.api_admin_link"
                                                      :label="$t('input.api_admin_link')"></v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row style="justify-content: right;">
                                    <v-col cols="6">
                                        <v-checkbox
                                        v-model="configApp.is_base"
                                        color="orange"
                                        :label="$t('input.is_base')"
                                        ></v-checkbox>
                                    </v-col> 
                                    <v-col cols="6">
                                        <v-checkbox
                                        v-model="configApp.is_enable"
                                        color="orange"
                                        :label="$t('input.enable')"
                                        ></v-checkbox>
                                    </v-col>    
                                </v-row>
                                <v-row v-if="configApp.is_base == false">    
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.d_company_id"
                                            :items="listCompany"
                                            :rules="configApp.is_base == false ?rules.d_company_id : ''"
                                            :label="$t('input.list_company_d')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeCompany()"
                                            chips
                                            hide-details="auto"
                                        >
                                      
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.d_senior_id"
                                            :items="listSenior"
                                            :rules="configApp.is_base == false?rules.d_senior_id:''"
                                            :label="$t('input.list_senior_d')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeSenior()"
                                            chips
                                            hide-details="auto"
                                
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.d_master_id"
                                            :rules="configApp.is_base == false?rules.d_master_id:''"
                                            :items="listMaster"
                                            :label="$t('input.list_master_d')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeMaster()"
                                            chips
                                            hide-details="auto"
                                        >
                                       
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.d_agent_id"
                                            :rules="configApp.is_base == false?rules.d_agent_id:''"
                                            :items="listAgent"
                                            :label="$t('input.list_agent_d')"
                                            item-text="user_name"
                                            item-value="_id"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.r_company_id"
                                            :items="listCompany"
                                            :rules="configApp.is_base == false?rules.r_company_id:''"
                                            :label="$t('input.list_company_r')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeCompanyR()"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.r_senior_id"
                                            :items="listSeniorR"
                                            :rules="configApp.is_base == false?rules.r_senior_id:''"
                                            :label="$t('input.list_senior_r')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeSeniorR()"
                                            chips
                                            hide-details="auto"
                                
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.r_master_id"
                                            :items="listMasterR"
                                            :rules="configApp.is_base == false?rules.r_master_id:''"
                                            :label="$t('input.list_master_r')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeMasterR()"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.r_agent_id"
                                            :items="listAgentR"
                                            :rules="configApp.is_base == false?rules.r_agent_id:''"
                                            :label="$t('input.list_agent_r')"
                                            item-text="user_name"
                                            item-value="_id"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.b_company_id"
                                            :items="listCompany"
                                            :rules="configApp.is_base == false?rules.b_company_id:''"
                                            :label="$t('input.list_company_b')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeCompanyB()"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.b_senior_id"
                                            :items="listSeniorB"
                                            :rules="configApp.is_base == false?rules.b_senior_id:''"
                                            :label="$t('input.list_senior_b')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeSeniorB()"
                                            chips
                                            hide-details="auto"
                                
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.b_master_id"
                                            :items="listMasterB"
                                            :rules="configApp.is_base == false?rules.b_master_id:''"
                                            :label="$t('input.list_master_b')"
                                            item-text="user_name"
                                            item-value="_id"
                                            @change="changeMasterB()"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="configApp.b_agent_id"
                                            :items="listAgentB"
                                            :rules="configApp.is_base == false?rules.b_agent_id:''"
                                            :label="$t('input.list_agent_b')"
                                            item-text="user_name"
                                            item-value="_id"
                                            chips
                                            hide-details="auto"
                                        >
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-row>
</template>

<script>
    export default {
        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            darkmode: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            listCompany: {
                type: Array,
                default: () => {
                    return []
                }
            },
            rules: {
                type: Object,
                default: function () {
                    return {
                        name: [
                            v => !!v || 'Name is required',
                        ],
                        
                        api_player_link: [
                            v => !!v || 'API player link is required',
                        ],
                        api_admin_link: [
                            v => !!v || 'API admin link is required',
                        ],
                        d_company_id: [
                            v => !!v || 'Companuy Dollar is required',
                        ],
                        
                        d_senior_id: [
                            v => !!v || 'Senior Dollor is required',
                        ],
                        d_master_id: [
                            v => !!v || 'Master Dollar is required',
                        ],
                        
                        d_agent_id: [
                            v => !!v || 'Agent Dollar link is required',
                        ],
                        r_company_id: [
                            v => !!v || 'Company Rield is required',
                        ],
                        
                        r_senior_id: [
                            v => !!v || 'Senior Riel link is required',
                        ],
                        r_master_id: [
                            v => !!v || 'Master Riel is required',
                        ],
                        
                        r_agent_id: [
                            v => !!v || 'Agent Riel link is required',
                        ],
                        b_company_id: [
                            v => !!v || 'Company Baht is required',
                        ],
                        
                        b_senior_id: [
                            v => !!v || 'Senior Baht link is required',
                        ],
                        b_master_id: [
                            v => !!v || 'Master Baht is required',
                        ],
                        
                        b_agent_id: [
                            v => !!v || 'Agent Baht link is required',
                        ]
                    }
                }
            },
            isValidate: {
                type: Boolean,
                default: true
            },
            configApp: {
                type: Object,
                default: function () {
                    return {
                        name: null,
                        is_enable: true,
                        is_base: false,
                        api_player_link: null,
                        api_admin_link: null,
                        d_company_id: null,
                        d_senior_id: null,
                        d_master_id: null,
                        d_agent_id: null,
                        r_company_id: null,
                        r_senior_id: null,
                        r_master_id: null,
                        r_agent_id: null,
                        b_company_id: null,
                        b_senior_id: null,
                        b_master_id: null,
                        b_agent_id: null,
                    }
                }
            }
        },
        data: () => ({
            listSenior: [],
            listMaster: [],
            listAgent: [],
            listSeniorR: [],
            listMasterR: [],
            listAgentR: [],
            listSeniorB: [],
            listMasterB: [],
            listAgentB: []
        }),
        computed: {
            valid: {
                // getter
                get: function () {
                    return this.isValidate
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:isValidate', newValue)
                }
            },
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit')
                }
            },
        
            async changeCompany(){
               this.listSenior = [];
               this.listMaster = [];
               this.listAgent = [];
                let data = {
                    user_id: this.configApp.d_company_id._id,
                    currency_type: 1
                };
                const response = await this.$request.post({
                    url: 'users/getListUserSenior',
                    data
                });
                if(response.data.code){
                    this.configApp.d_senior_id = null;
                    this.configApp.d_master_id = null;
                    this.configApp.d_agent_id = null;
                    this.listSenior = response.data.data;
                }
            },
            async changeSenior(){
               this.listMaster = [];
               this.listAgent = [];
                let data = {
                    user_id: this.configApp.d_senior_id._id,
                    currency_type: 1
                };
                const response = await this.$request.post({
                    url: 'users/getListUserMaster',
                    data
                });
                if(response.data.code){
                    this.configApp.d_master_id = null;
                    this.configApp.d_agent_id = null;
                    this.listMaster = response.data.data;
                }
            },
            async changeMaster(){
               this.listAgent = [];
               
                let data = {
                    user_id: this.configApp.d_master_id._id,
                    currency_type: 1
                };
                console.log('data',data)
                const response = await this.$request.post({
                    url: 'users/getListUserAgent',
                    data
                });
                if(response.data.code){
                    this.configApp.d_agent_id = null;
                    this.listAgent = response.data.data;
                }
            },
            async changeCompanyR(){
               this.listSeniorR = [];
               this.listMasterR = [];
               this.listAgentR = [];
                let data = {
                    user_id: this.configApp.r_company_id._id,
                    currency_type: 2
                };
                const response = await this.$request.post({
                    url: 'users/getListUserSenior',
                    data
                });
                if(response.data.code){
                    this.configApp.r_senior_id = null;
                    this.configApp.r_master_id = null;
                    this.configApp.r_agent_id = null;
                    this.listSeniorR = response.data.data;
                }
            },
            async changeSeniorR(){
               this.listMasterR = [];
               this.listAgentR = [];
                let data = {
                    user_id: this.configApp.r_senior_id._id,
                    currency_type: 2
                };
                const response = await this.$request.post({
                    url: 'users/getListUserMaster',
                    data
                });
                if(response.data.code){
                    this.configApp.r_master_id = null;
                    this.configApp.r_agent_id = null;
                    this.listMasterR = response.data.data;
                }
            },
            async changeMasterR(){
               this.listAgentR = [];
               
                let data = {
                    user_id: this.configApp.r_master_id._id,
                    currency_type: 2
                };
                const response = await this.$request.post({
                    url: 'users/getListUserAgent',
                    data
                });
                if(response.data.code){
                    this.configApp.r_agent_id = null;
                    this.listAgentR = response.data.data;
                }
            },
            async changeCompanyB(){
               this.listSeniorB = [];
               this.listMasterB = [];
               this.listAgentB = [];
                let data = {
                    user_id: this.configApp.b_company_id._id,
                    currency_type: 3
                };
                const response = await this.$request.post({
                    url: 'users/getListUserSenior',
                    data
                });
                if(response.data.code){
                    this.configApp.b_senior_id = null;
                    this.configApp.b_master_id = null;
                    this.configApp.b_agent_id = null;
                    this.listSeniorB = response.data.data;
                }
            },
            async changeSeniorB(){
               this.listMasterB = [];
               this.listAgentB = [];
                let data = {
                    user_id: this.configApp.b_senior_id._id,
                    currency_type: 3
                };
                const response = await this.$request.post({
                    url: 'users/getListUserMaster',
                    data
                });
                if(response.data.code){
                    this.configApp.b_master_id = null;
                    this.configApp.b_agent_id = null;
                    this.listMasterB = response.data.data;
                }
            },
            async changeMasterB(){
               this.listAgentB = [];
               
                let data = {
                    user_id: this.configApp.b_master_id._id,
                    currency_type: 3
                };
                const response = await this.$request.post({
                    url: 'users/getListUserAgent',
                    data
                });
                if(response.data.code){
                    this.configApp.b_agent_id = null;
                    this.listAgentB = response.data.data;
                }
            },
            displayCompany(_id) {
                return this.listCompany.find(item => item._id === _id).user_name
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
